import React, { useEffect } from 'react';

const Cookies = () => {

    const isStgFlag = process.env.REACT_APP_IS_STG === 'true';

    // staging ids
    const stgDataTenantUuid = 'bc815ddc-95e6-4a27-a242-daa6d544789f'
    const stgDataDomainUuid = 'eb278286-36f9-4670-8303-fb7d89205396'

    // production ids
    const prodDataTenantUuid = '31270bbd-6025-4430-a211-12fb9c5112dd'
    const prodDataDomainUuid = 'e4ff20e1-92fc-46ef-b867-19fea0058dcd'

    const dataTenantUuid = isStgFlag ? stgDataTenantUuid : prodDataTenantUuid
    const dataDomainUuid = isStgFlag ? stgDataDomainUuid : prodDataDomainUuid

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://cdn-prod.securiti.ai/consent/cookie-consent-sdk-loader.js';
        script.setAttribute('data-tenant-uuid', `${dataTenantUuid}`);
        script.setAttribute('data-domain-uuid', `${dataDomainUuid}`);
        script.setAttribute('data-backend-url', 'https://app.securiti.ai');
        script.setAttribute('data-skip-css', 'false');
        script.defer = true;
        const parent_node = document.head || document.body;
        parent_node.appendChild(script);


        const auto_blocking_script = document.createElement('script');
        auto_blocking_script.type = 'text/javascript';
        auto_blocking_script.src = `https://cdn-prod.securiti.ai/consent/auto_blocking/${dataTenantUuid}/${dataDomainUuid}.js`;
        document.head.appendChild(auto_blocking_script);

        // removing when unmounds
        return () => {
            document.head.removeChild(script);
            document.head.removeChild(auto_blocking_script);
        };

    },[])

    return null;
}

export default Cookies